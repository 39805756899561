import { createHttpEndpoint } from '../../utils'

import type {
  PostClientLoginResponse,
  PostClientSubscriptionResponse,
} from './client-types'

/**
 * ClientLoginView
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/auth-cookie-consent-api/definition#tag/legacy_auth/operation/post-bm-client-login}
 */
export const postClientLogin = createHttpEndpoint<PostClientLoginResponse>({
  method: 'POST',
  operationId: 'post-bm-client-login',
  path: '/bm/client/login/',
})

/**
 * ClientSubscriptionView
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/auth-cookie-consent-api/definition#tag/legacy_auth/operation/post-bm-client-subscription}
 */
export const postClientSubscription =
  createHttpEndpoint<PostClientSubscriptionResponse>({
    method: 'POST',
    operationId: 'post-bm-client-subscription',
    path: '/bm/client/subscription/',
  })

/**
 * LogoutView
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/auth-cookie-consent-api/definition#tag/legacy_auth/operation/post-logout}
 */
export const postClientLogout = createHttpEndpoint({
  method: 'POST',
  operationId: 'post-logout',
  path: '/bm/client/logout',
})

/**
 * DeleteAccountView
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/auth-cookie-consent-api/definition#/legacy_auth/delete-bm-client}
 */
export const deleteClientAccount = createHttpEndpoint({
  defaultQueryParams: { force: false },
  method: 'DELETE',
  operationId: 'delete-bm-client',
  path: '/bm/client',
})
